<template>
    <v-bottom-sheet
        v-model="showSheet"
        :max-width="$vuetify.breakpoint.smAndUp ? 'max-content' : undefined"
        persistent
        hide-overlay
        no-click-animation
    >
        <v-card class="pt-6 pb-3 rounded-b-0">
            <v-card-text class="d-flex justify-center gap-6">
                <div class="d-flex align-center">
                    <v-card
                        height="55"
                        width="55"
                        outlined
                        class="d-flex flex-column justify-center align-center"
                    >
                        <v-img
                            src="https://pointscentral.blob.core.windows.net/images/annual-meeting_logo_icon.png"
                            max-width="50px"
                            contain
                        />
                    </v-card>
                </div>

                <div class="content-width">
                    <div class="text-h6 font-weight-regular">
                        A better experience
                    </div>
                    <div>
                        Open this in the Annual Owners Meeting app to get a richer experience.
                    </div>
                </div>
            </v-card-text>

            <v-card-actions>
                <v-btn
                    outlined
                    class="flex-grow-1"

                    @click="onNotNowClick"
                >
                    Not Now
                </v-btn>

                <v-btn
                    color="primary"
                    dark
                    class="flex-grow-1"
                    @click="onInstallClick"
                >
                    Open App
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-bottom-sheet>
</template>

<script lang="ts">
import { computed, defineComponent, getCurrentInstance, onMounted, ref, watch } from "vue";

export default defineComponent({
    name: "InstallPrompt",

    props:
        // Vue gives an error for passing an event as an object.
        // I'm not sure how to fix this so leave off the type for now.
        // eslint-disable-next-line vue/require-prop-types
        ["deferredPrompt"]
    ,

    setup(props) {
        const hideInstallPromptKey = "annualMeetingHideInstallPrompt";
        const sheet = ref(false);

        const showSheet = computed({
            get: (): boolean => {
                return sheet.value &&
                !!getCurrentInstance()?.proxy.$vuetify.breakpoint.mobile &&
                    !localStorage.getItem(hideInstallPromptKey);
            },
            set: (value: boolean): void => {
                sheet.value = value;
            }
        });

        watch(() => props.deferredPrompt, (value) => {
            if (value) {
                sheet.value = true;
            }
        });

        onMounted(() => {
            if (props.deferredPrompt) {
                sheet.value = true;
            }
        });

        async function onInstallClick(): Promise<void> {
            if (props.deferredPrompt) {
                props.deferredPrompt.prompt();
                const choice = await props.deferredPrompt.userChoice;
                if (choice.outcome === "accepted") {
                    sheet.value = false;
                }
            }
        }

        function onNotNowClick(): void {
            localStorage.setItem(hideInstallPromptKey, new Date().toISOString());
            sheet.value = false;
        }

        return {
            showSheet,
            onInstallClick,
            onNotNowClick
        };
    }
});
</script>

<style lang="scss" scoped>
.content-width {
    max-width: 256px;
}
</style>
