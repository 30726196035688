<template>
    <v-app id="app">
        <v-app-bar
            app
            dark
            color="brand-primary"
        >
            <v-container class="d-flex align-center py-0">
                <v-app-bar-title>
                    <router-link
                        :to="{ name: 'home' }"
                        exact
                    >
                        <v-img
                            max-width="140"
                            contain
                            src="https://pointscentral.blob.core.windows.net/images/point_s_logo_dark@460.png"
                        />
                    </router-link>
                </v-app-bar-title>

                <v-spacer />

                <v-img
                    :max-height="$vuetify.breakpoint.mobile ? 56 : 64"
                    :max-width="$vuetify.breakpoint.mobile ? 125 : 140"
                    contain
                    src="https://pointscentral.blob.core.windows.net/images/all-trac-truck.png"
                />
            </v-container>
        </v-app-bar>

        <v-main v-scroll="onScroll">
            <v-container
                :class="$vuetify.breakpoint.mobile ? 'pa-0' : 'py-0'"
                style="height: 100%;"
            >
                <v-error-dialog />
                <v-application-update-alert />
                <install-prompt :deferred-prompt="deferredPrompt" />

                <v-scroll-y-transition hide-on-leave>
                    <router-view />
                </v-scroll-y-transition>

                <v-fab-transition v-if="showScrollFab">
                    <v-btn
                        bottom
                        color="brand-secondary"
                        dark
                        fab
                        fixed
                        right
                        small
                        style="bottom: 68px;"
                        @click="scrollToTop"
                    >
                        <v-icon small>
                            fas fa-chevron-up
                        </v-icon>
                    </v-btn>
                </v-fab-transition>
            </v-container>
        </v-main>

        <v-bottom-navigation
            app
            background-color="brand-primary"
            color="white"
            dark
            fixed
            grow
        >
            <v-btn
                v-for="item in navigationItems"
                :key="item.routeName"
                :to="{ name: item.routeName }"
                exact
            >
                <span>{{ item.title }}</span>
                <v-icon>{{ item.icon }}</v-icon>
            </v-btn>
        </v-bottom-navigation>
    </v-app>
</template>

<script lang="ts">
import { ApplicationInsights, DistributedTracingModes } from "@microsoft/applicationinsights-web";
import { computed, defineComponent, getCurrentInstance, onMounted, onUnmounted, ref } from "vue";
import InstallPrompt from "./components/InstallPrompt.vue";

function configureApplicationInsights(): void {
    const appInsights = new ApplicationInsights({ config: {
        instrumentationKey: process.env.VUE_APP_APPLICATIONINSIGHTS_INSTRUMENTATIONKEY,
        distributedTracingMode: DistributedTracingModes.W3C,
        autoTrackPageVisitTime: true,
        enableAutoRouteTracking: true
    } });
    appInsights.loadAppInsights();
    appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
}

export default defineComponent({
    name: "App",

    components: {
        InstallPrompt
    },

    setup() {
        const navigationItems: { title: string; icon: string; routeName: string }[] = [
            {
                title: "Agenda",
                icon: "fa-calendar-day fa-fw",
                routeName: "agenda"
            },
            /*
            {
                title: "Activities",
                icon: "fas fa-calendar-week fa-fw",
                routeName: "activities"
            },
            {
                title: "FAQ",
                icon: "fas fa-question-circle fa-fw",
                routeName: "frequently-asked-questions"
            },
            {
                title: "Nominees",
                icon: "fas fa-users fa-fw",
                routeName: "nominees"
            },
            */
            {
                title: "Contact",
                icon: "fa-envelope fa-fw",
                routeName: "contact"
            }
        ];
        const deferredPrompt = ref();
        const offsetTop = ref(0);
        const showScrollFab = computed(() => offsetTop.value > 60);

        function onBeforeInstallPrompt(event: Event): void {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            event.preventDefault();
            // Stash the event so it can be triggered later.
            deferredPrompt.value = event;
        }

        onMounted(() => {
            configureApplicationInsights();
            window.addEventListener("beforeinstallprompt", onBeforeInstallPrompt);
        });

        onUnmounted(() => {
            window.removeEventListener("beforeinstallprompt", onBeforeInstallPrompt);
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        function onScroll(event: any): void {
            offsetTop.value = event.target.scrollingElement.scrollTop;
        }

        function scrollToTop(): void {
            getCurrentInstance()?.proxy.$vuetify.goTo("#app", { duration: 300, offset: 0 });
        }

        return {
            navigationItems,
            deferredPrompt,
            offsetTop,
            showScrollFab,
            onScroll,
            scrollToTop
        };
    }
});
</script>

<style lang="scss">
html, body {
    min-height: 100%;
}
</style>
