/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { globals } from "@/utilities/globals";
import Router, { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
    {
        path: "/",
        name: "home",
        component: () => import("@/views/Home.vue")
    },
    {
        path: "/welcome",
        name: "welcome",
        component: () => import("@/views/Welcome.vue")
    },
    {
        path: "/agenda",
        name: "agenda",
        beforeEnter: () => window.open("https://pointscentral.blob.core.windows.net/images/2025_annual_meeting_placeholder_agenda.pdf", "_blank")
        //component: () => import("@/views/Agenda.vue")
    },
    /*
    {
        path: "/activities",
        name: "activities",
        component: () => import("@/views/Activities.vue")
    },
    {
        path: "/frequently-asked-questions",
        name: "frequently-asked-questions",
        component: () => import("@/views/FAQ.vue")
    },
    {
        path: "/nominees",
        name: "nominees",
        component: () => import("@/views/Nominees.vue")
    },
    */
    {
        path: "/contact",
        name: "contact",
        component: () => import("@/views/Contact.vue")
    }
];

export const createRouter = () => {
    const router = new Router({
        mode: "history",
        base: globals.BASE_URL,
        routes
    });

    return router;
};
