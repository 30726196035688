import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                "brand-primary": "#004c98",
                "brand-secondary": "#2CB249"
            },
            dark: {
                "brand-primary": "#004c98",
                "brand-secondary": "#2CB249"
            }
        },
        options: { customProperties: true }
    }
});
