import { globals } from "@/utilities/globals";
import PointSCommon from "@points/common";
import { CommonPluginOptions } from "@points/common/dist/lib/models/CommonPluginOptions";
import { createPinia, PiniaVuePlugin } from "pinia";
import Vue from "vue";
import Router from "vue-router";
import VueTheMask from "vue-the-mask";
import { VNode } from "vue/types/umd";
import App from "./App.vue";
import "./plugins/axios";
import vuetify from "./plugins/vuetify";
import { createRouter } from "./router";
import "./styles/app.scss";

Vue.config.productionTip = false;
Vue.config.devtools = !!globals.VUE_APP_DEVTOOLS;

// Register pinia
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
// Create router (depends on pinia)
Vue.use(Router);
const router = createRouter();
// Register common config/handler/components (depends on pinia and router)
Vue.use<CommonPluginOptions>(PointSCommon, {
    ...globals,
    pinia,
    router
});

Vue.use(VueTheMask);

const {
    hostname,
    href,
    protocol
} = location;
if (hostname !== "localhost") {
    const isApex = hostname.split(".")[1] === "com";
    const isHttp = protocol === "http:";
    if (isApex && isHttp)
        location.replace(href.replace("http://", "https://www."));
    else if (isApex) location.replace(href.replace("https://", "https://www."));
    else if (isHttp) location.replace(href.replace("http://", "https://"));
}

new Vue({
    pinia,
    router,
    vuetify,
    render: (h): VNode => h(App)
}).$mount("#app");
